body {
  font-family: 'Source Sans Pro', sans-serif;
  @apply bg-gray-800 text-white;
}

.home-gradient {
  background-image: linear-gradient(-225deg, #cbbacc 0%, #2580b3 100%);
}

.orange-gradient {
  background-color: #f39f86;
  background-image: linear-gradient(315deg, #f39f86 0%, #f9d976 74%);
}

.error-gradient {
  background: rgb(253, 29, 29);
  background: linear-gradient(-225deg, rgba(253, 29, 29, 1) 0%, rgba(253, 141, 29, 1) 100%);
}

.smooth--transition {
  @apply transition-all duration-300 ease-in-out;
}

.long--transition {
  @apply transition-all duration-1000 ease-in-out;
}

.button--primary {
  @apply shadow-lg py-4 px-8 rounded bg-gray-50 text-black hover:bg-gray-200 smooth--transition text-center;
}

.button--action {
  @apply shadow-lg py-4 px-8 rounded text-black m-2;
}

.button--link {
  @apply bg-blue-700 py-2 px-6 rounded mt-4 text-white hover:bg-blue-600 smooth--transition;
}

.button--bounce {
  @apply transform transition hover:scale-125 duration-300 ease-in-out;
}

.selected-spaceship > div > div {
  @apply shadow-lg shadow-cyan-500;
}

.selected-blueprint {
  @apply shadow-lg shadow-cyan-500;
}

.number-input:invalid {
  @apply bg-red-600;
}

.main-app-name {
  background-image: linear-gradient(45deg, #73ccdb, #eb98eb);
  background-clip: text;
  color: transparent;
}

.admin-warn-background {
  @apply bg-red-800;
}

.app-logo {
  animation: app-logo-wiggle infinite 15s alternate;
}

@keyframes app-logo-wiggle {
  from {
    transform: rotate(-15deg);
  }

  to {
    transform: rotate(15deg);
  }
}
