/* Source Sans Pro Google Font, downloaded via https://google-webfonts-helper.herokuapp.com/fonts/source-sans-pro?subsets=latin,latin-ext
   Google is tracking us otherwise :)
 */

/* source-sans-pro-regular - Google Font */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Source Sans Pro Regular'),
    url('../../public/fonts/source-sans-pro-v18-latin-ext_latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../../public/fonts/source-sans-pro-v18-latin-ext_latin-regular.woff')
      format('woff'),
    /* Modern Browsers */ url('../../public/fonts/source-sans-pro-v18-latin-ext_latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../../public/fonts/source-sans-pro-v18-latin-ext_latin-regular.svg#SourceSansPro')
      format('svg'); /* Legacy iOS */
}

/* source-sans-pro-italic - Google Font */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local('Source Sans Pro Italic'),
    url('../../public/fonts/source-sans-pro-v18-latin-ext_latin-italic.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../../public/fonts/source-sans-pro-v18-latin-ext_latin-italic.woff') format('woff'),
    /* Modern Browsers */ url('../../public/fonts/source-sans-pro-v18-latin-ext_latin-italic.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../../public/fonts/source-sans-pro-v18-latin-ext_latin-italic.svg#SourceSansPro')
      format('svg'); /* Legacy iOS */
}

/* source-sans-pro-700 - Google Font */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local('Source Sans Pro 700'),
    url('../../public/fonts/source-sans-pro-v18-latin-ext_latin-700.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../../public/fonts/source-sans-pro-v18-latin-ext_latin-700.woff') format('woff'),
    /* Modern Browsers */ url('../../public/fonts/source-sans-pro-v18-latin-ext_latin-700.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../../public/fonts/source-sans-pro-v18-latin-ext_latin-700.svg#SourceSansPro')
      format('svg'); /* Legacy iOS */
}

/* source-sans-pro-700italic - Google Font */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: local('Source Sans Pro 700italic'),
    url('../../public/fonts/source-sans-pro-v18-latin-ext_latin-700italic.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../../public/fonts/source-sans-pro-v18-latin-ext_latin-700italic.woff')
      format('woff'),
    /* Modern Browsers */ url('../../public/fonts/source-sans-pro-v18-latin-ext_latin-700italic.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../../public/fonts/source-sans-pro-v18-latin-ext_latin-700italic.svg#SourceSansPro')
      format('svg'); /* Legacy iOS */
}
