.roadmap-container {
  min-height: 70vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.roadmap {
  height: auto;
  position: relative;
}

.roadmap ul {
  list-style: none;
}

.roadmap ul li {
  padding: 1rem;
  background-color: #0e4565;
  border-radius: 10px;
  margin-bottom: 2rem;
}

.roadmap ul li:last-child {
  margin-bottom: 0;
}

.roadmap-content h2 {
  font-weight: 500;
  font-size: 1.7rem;
  line-height: 2rem;
  margin-bottom: 1.4rem;
}

.roadmap-content p {
  font-size: 1.1rem;
  line-height: 1.8rem;
  font-weight: 500;
}

.roadmap-content .deadline {
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

@media only screen and (min-width: 768px) {
  .roadmap:before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 2px;
    height: 100%;
    background-image: linear-gradient(transparent, #385c6d 10%, rgb(90, 41, 105) 90%, transparent);
  }

  .roadmap ul li {
    width: 50%;
    position: relative;
    margin-bottom: 5rem;
  }

  .roadmap ul li:nth-child(odd) {
    float: left;
    clear: right;
    transform: translateX(-30px);
    border-radius: 20px 0px 20px 20px;
  }

  .roadmap ul li:nth-child(even) {
    float: right;
    clear: left;
    transform: translateX(30px);
    border-radius: 0px 20px 20px 20px;
  }

  .roadmap ul li::before {
    content: '';
    position: absolute;
    height: 0.8rem;
    width: 0.8rem;
    border-radius: 50%;
    background-color: #bfbfbf;
    top: 0px;
  }

  .roadmap ul li:nth-child(odd)::before {
    transform: translate(50%, 500%);
    right: -1.9rem;
  }

  .roadmap ul li:nth-child(even)::before {
    transform: translate(-50%, 500%);
    left: -1.9rem;
  }

  .roadmap-content .deadline {
    position: absolute;
    top: -2rem;
  }

  .roadmap ul li:hover {
    box-shadow: 0 0 1rem #2580b3;
  }

  .roadmap ul li:hover::before {
    box-shadow: 0 0 1rem #2580b3;
  }
}
