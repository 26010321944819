.game--spaceship {
  margin-top: 2rem;
  margin-bottom: 2rem;
  transform: translatey(0px);
  animation: float 2s ease-in-out infinite;
  @apply transition-opacity duration-300 ease-in-out;
}

@keyframes float {
  0% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    transform: translatey(0px);
  }
  50% {
    box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2);
    transform: translatey(-20px);
  }
  100% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    transform: translatey(0px);
  }
}

.action--bubble {
  position: relative;
  @apply py-2 px-4 mb-4  rounded;
}

.action--bubble:after {
  content: '';
  position: absolute;
  top: 50%;
  width: 0;
  height: 0;
  border: 1.5em solid transparent;
}

.action--bubble--self {
  @apply bg-green-600;
}

.action--bubble--self:after {
  left: 0;
  @apply border-r-green-600;
  border-left: 0;
  border-bottom: 0;
  margin-top: -1em;
  margin-left: -1em;
}

.action--bubble--opponent {
  @apply bg-red-600;
}

.action--bubble--opponent:after {
  right: 0;
  @apply border-l-red-600;
  border-right: 0;
  border-bottom: 0;
  margin-top: -1em;
  margin-right: -1em;
}

.emoji--bubble {
  @apply text-center text-2xl py-4 mx-auto mb-5;
  position: relative;
  background: #000000;
  line-height: 4rem;
  min-height: 4rem;
  color: #ffffff;
  border-radius: 10px;
  padding: 0px;
}

.emoji--bubble:after {
  content: '';
  position: absolute;
  display: block;
  width: 0;
  z-index: 1;
  border-style: solid;
  border-color: #000000 transparent;
  border-width: 20px 20px 0;
  bottom: -20px;
  left: 50%;
  margin-left: -20px;
}

.emoji--bubble--hidden {
  visibility: hidden;
}

.utility--effect--icon {
  @apply mr-2;
}

.action--canvas--self,
.action--canvas--opponent {
  @apply h-full;
  overflow: hidden;
  grid-row-start: 1;
  grid-column-start: 1;
}

.action--canvas--self .standardLaser {
  @apply mt-20;
  width: 100px;
  height: 0x;
  box-shadow: 0 0 40px 30px #0ff;
  animation: moveRight 800ms ease-in 120ms 1 normal forwards;
}
.action--canvas--opponent .standardLaser {
  @apply mt-20;
  width: 100px;
  height: 0x;
  box-shadow: 0 0 40px 30px #0ff;
  float: right;
  animation: moveLeft 800ms ease-in 120ms 1 normal forwards;
}

.action--canvas--self .railgun {
  @apply mt-20;
  width: 100px;
  height: 0x;
  box-shadow: 0 0 40px 30px #9c0c02;
  animation: moveRight 800ms ease-in 120ms 1 normal forwards;
}
.action--canvas--opponent .railgun {
  @apply mt-20;
  width: 100px;
  height: 0x;
  box-shadow: 0 0 40px 30px #9c0c02;
  float: right;
  animation: moveLeft 800ms ease-in 120ms 1 normal forwards;
}

@keyframes moveRight {
  100% {
    transform: translateX(1000px);
    width: 120px;
  }
}
@keyframes moveLeft {
  100% {
    transform: translateX(-1000px);
    width: 120px;
  }
}
